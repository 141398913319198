@import url(https://fonts.cdnfonts.com/css/general-sans);
/* .export-btn-holder{
    position: relative;
} */

@font-face {
  font-family: "GeneralSans-Bold";
  src: local("GeneralSans-Bold"),
    url(/app/static/media/GeneralSans-Regular.6d91885b.ttf) format("truetype");
  /* font-weight: bold; */
}


@font-face {
  font-family: "GeneralSans-Regular";
  src: local("GeneralSans-Regular"),
    url(/app/static/media/GeneralSans-Regular.6d91885b.ttf) format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "GeneralSans-Medium";
  src: local("GeneralSans-Medium"),
    url(/app/static/media/GeneralSans-Medium.c729d674.ttf) format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "GeneralSans-SemiBold";
  src: local("GeneralSans-SemiBold"),
    url(/app/static/media/GeneralSans-Semibold.f6a5baca.ttf) format("truetype");
  /* font-weight: bold; */
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.goog-te-gadget .goog-te-combo {
  color: blue !important;
}


body::-webkit-scrollbar {
  display: none;
}

#root {
  /* font-family: "GeneralSans"; */
  height: 100%;
}



.box-size {
  height: 100%;
}


@media screen and (max-width: 900px) and (min-width: 600px) {

  .login-bound-font {
    font-size: 2rem
  }

}
.row-travel-1 {
  padding: 7% 5% 5% 10%;
  background-image: url(/app/static/media/Background.1c6a02fe.png);
  background-size: 100% 100%;
}

.oona-logo {
  width: 75%;
}

.ant-layout-header {
  border-image: linear-gradient(90deg, #4ac6bb, #7a4cb7 93.75%)1;
  border-width: 0 0 6px;
  border-style: solid;
  background: #482c77;
}

.ant-tabs-tab {
  justify-content: space-around;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
  justify-content: space-around;
}

.OTPInput {
  width: 40px;
}

.row-travel-4 {
  padding: 7% 5% 5% 10%;
  background-image: url(/app/static/media/AccidentBackground.453be45b.png);
  background-size: 100% 100%;
}

.recruit-background {
  background-image: url(/app/static/media/bg.221b66d5.png);
  overflow: hidden;
  background-size: 100% 100%;
  height: 100vh;
  position: sticky;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #673FAE !important;
  border-color: #673FAE !important;
  color: white;
}

.border-performance-card .ant-card-body {
  padding: 10px;
}

.mob-invalid-photo {
  display: none;
}

.ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered>div>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
  justify-content: center;
  flex-flow: column-reverse;
}


@media screen and (max-width:998px) and (min-width:601px) {

  .tab-inner-performance .ant-tabs-tab {
    min-width: 22rem !important;
  }

  .tab-inner-learn .ant-tabs-tab {
    min-width: 16rem !important;
  }

}

@media screen and (max-width:1100px) and (min-width:601px) {

  .row-travel-1 {
    padding: 9% 1% 1% 1% !important;
  }

  .row-travel-4 {
    padding: 9% 1% 1% 1% !important;
  }

  .row-travel-3 {
    padding: 9% 1% 1% 1% !important;
  }

  .row-travel-2 {
    padding: 9% 1% 1% 1% !important;
  }

  .row-travel {
    padding: 9% 1% 1% 1% !important;
  }

  .tab-inner-travel .ant-tabs-tab {
    min-width: 230px;
  }
}

@media screen and (max-width:600px) {
  .row-travel-1 {
    background-image: url(/app/static/media/mob.9397d57f.png);
  }

  .recruit-background {
    margin-top: 15% !important;
    height: 100vh;
  }

  .oona-travel-otp {
    padding: 40% 0% !important;
  }

  .row-travel-4 {
    background-image: url(/app/static/media/accidentBackgroundMobile.276e1ee2.png);
  }


  .mob-invalid-photo {
    display: block;
  }

  .preview-content {
    padding: 5% 3% !important;
    align-items: center;
  }

  .row-travel-2 {
    background-image: url(/app/static/media/homemobbackground.9f598ce9.png) !important;
  }

  .risk-inspection-div {
    display: block !important
  }

  .team-count-card .ant-card-body {
    padding: 12px;
  }

  .graph-left-card .ant-card-body {
    padding: 15px;
  }

  .displayed_Image_middle {
    display: block !important;
  }

  .oona__image__height {
    width: 249px !important
  }


}


.row-travel-2 {
  padding: 7% 5% 5% 10%;
  background-image: url(/app/static/media/homeBackground.31f89c41.png);
  background-size: 100% 100%;
}


.row-travel-3 {
  padding: 7% 5% 5% 10%;
  background-image: url("/app/static/media/Motor Insurance Policy - Car.e59fa799.png");
  background-size: 100% 100%;
}


.back-img-1 {
  background-image: url("/app/static/media/Frame 1000004288.4bff81de.png");
  background-size: 100% 100%;
  color: white;
}

.accordian-image {
  background-image: url("/app/static/media/Frame 1000004288.4bff81de.png");
  background-size: 100% 100%;
  color: white;
}

.accordian-image1 {
  background-image: url("/app/static/media/Frame 1000004288 (1).39306fea.png");
  background-size: 100% 100%;
  color: white;
}

.accordian-image-4 {
  background-size: 100% 100%;
  color: white;
  background: linear-gradient(74.62deg, #6F5000 -10.07%, #D1A737 99.04%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.accordian-image2 {
  background-image: url("/app/static/media/Frame 1000004288 (2).5ab7c31f.png");
  background-size: 100% 100%;
  color: white;
}

/* new css */

* {
  font-family: "GeneralSans-Regular";
}

.card-customer .ant-card-body {
  padding: 0px;
}

.padding-sale-inner {
  padding: 10px;
}

.customer-card-inner-row .ant-image {
  margin-right: 5px;
}

.customer__tag {
  font-size: 12px;
}

.sales-card {
  width: 95%;
  border-radius: 6px;
  min-height: 60px;
}

.to-do-card {
  width: 98% !important;
}

.number-1 {
  width: 75px;
  justify-content: center;
  display: flex;
  color: #C8CDD1;
  /* background-color: #F4F4F4; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.cell.today .number-2 {
  width: 75px;
  justify-content: center;
  display: flex;
  color: white;
  background-color: #673FAE;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.cell.selected .number-2 {
  width: 75px;
  justify-content: center;
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: white;
  background-color: #673FAE;
}

.number-2 {
  width: 75px;
  justify-content: center;
  display: flex;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  color: #C8CDD1;
  background-color: #F4F4F4;
}

.activity-tracker-inner-card .ant-card-body {
  padding: 10px 25px;
}

.col {
  color: white;
  justify-content: center;
  display: flex;
  align-items: flex-end;
}

.activity-tracker-card {
  background-color: #482C77;
  margin-bottom: 50px
}

.activity-tracker-inner-card {
  margin: 15px 0px;
}

.card-sale-inner .ant-card-body {
  padding: 0px;
}

.border-inner {
  border-left: 3px solid #4AC6BB;
  height: 30%;
}

.sales-card .ant-card-body {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  padding: 10px;
  align-items: center;
}

.arrow-sales {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-customer {
  box-shadow: 0px 8px 20px 0px #00000014;
  border-radius: 8px;
  margin: 5px;
}

.avatar-icon {
  width: 50px !important;
  height: 50px !important;
  line-height: 37px !important;
  font-size: 25px !important;
  color: #482c77;
  background-color: #F1EDF8;
}

.home-tab-bottom {
  display: flex;
  justify-content: space-evenly;
  text-align: center;
}

.home-class {
  padding: 15px;
  background: transparent;
  border: none;
  border-top: 3px solid #4AC6BB;
  cursor: pointer;
}

.performance-class {
  padding: 15px;
  background: transparent;
  border: none;
  border-top: 3px solid #4AC6BB;
  cursor: pointer;
}

.sales-class {
  padding: 15px;
  background: transparent;
  border: none;
  border-top: 3px solid #4AC6BB;
  cursor: pointer;
}

.account-class {
  padding: 15px;
  background: transparent;
  border: none;
  border-top: 3px solid #4AC6BB;
  cursor: pointer;
}

.customer-class {
  padding: 15px;
  background: transparent;
  border: none;
  border-top: 3px solid #4AC6BB;
  cursor: pointer;
}

.home-tab-nav {
  background: transparent;
  border: none;
  padding: 15px;
  cursor: pointer;
}

.home-tab-inner-card .ant-card-body {
  padding: 0px;
}

.oona-tab-nav {
  padding: 0% 12%;
}

.customer-card-inner {
  padding: 24px;
  border-left: 3px solid #4AC6BB;
}

.customer-inner-heading {
  font-size: 16px;
  font-weight: 600;
  margin: 10px 0px;
}

.card-padding-customer .ant-card-body {
  padding: 10px 10px;
}

.card-padding-customer {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}

.icon-dashboard .ant-image {
  float: right
}

.font-10 {
  font-size: 12px;
}

.customer-card-inner-1 {
  padding: 24px;
}

.customer-card-inner-row {
  padding: 10px;
  background-color: #F2F2F3;
  text-align: center;
}

.customer-card-inner-row-1 {
  padding: 10px;
  background-color: #F2F2F3;
  text-align: center;
  width: 97%;
  margin-bottom: 20px;
}

.card-sale-inner {
  width: 97%;
  margin-bottom: 20px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.08);
  border-radius: 6px;
}

.heading-modal-content div {
  padding: 1% 0%;
  text-align: center;
}

.heading-modal-content h4 {
  text-align: center;
  color: #482C77;
}

.heading-modal-content {
  text-align: center;
  font-weight: bold;
}

.font-inner {
  color: #482c77;
}

.modal-popup .ant-input-prefix {
  color: #4AC6BB;
  font-size: 20px;
  font-weight: 500;
}

.si-refrence {
  display: flex;
  margin-bottom: 10px;
  grid-gap: 2px;
  gap: 2px;
  color: #4AC6BB;
}

.ant-input-prefix,
.ant-input-suffix {
  font-weight: bold;
}

.modal-popup .ant-input-suffix {
  color: #4AC6BB;
  font-size: 20px;
}

.date-button {
  border-color: #482c77;
  color: #482c77;
  border-radius: 24px;
  padding: 4px 13px;
}

.download-button {
  border-color: #673FAE;
  color: #673FAE;
  border-radius: 24px;
  padding: 0px 5px;
  height: 24px;
  float: right;
}

.logout-button {
  border-color: #673FAE;
  color: #673FAE;
  border-radius: 24px;
  padding: 0px 10px;
  height: 24px;
  float: right;
}

@media screen and (max-width:600px) {
  .logout-button {
    border-color: #673FAE;
    color: #673FAE;
    border-radius: 24px;
    padding: 0px 10px;
    height: 24px;
    float: right;
  }
}

.font-card-commercial {
  font-size: 12px;
  padding: "0% 25% 0% 0%";
  color: #e5dddde3;
}

.heading-modal-content-1 {
  text-align: center;
  font-size: 24px;
  text-shadow: 0 0 #3d3636;
  font-weight: bold;
  color: #482c77;
}

.ant-input-affix-wrapper>input.ant-input {
  background-color: transparent;
}

.border-performance-card {
  margin: 10px 0px;
  border-left: 3px solid #482c77;
}

.input-img {
  margin-left: 2px;
  width: 80%;
}

.id-font {
  color: #482c77;
  font-weight: 600;
}

.link-input .ant-input {
  color: white;
  padding: 10px 10px !important;
  font-size: 19px;
  margin-top: 5px;
}

.card-background .ant-card-body {
  padding: 15px;
}

.card-inspection-align {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.risk-inspection-image {
  margin: 5px;
}

.risk-inspection-image .ant-card-body {
  padding: 0px !important;
}

.next-button-travel-risk {
  width: 75%;
  border-radius: 10px;
  color: #482C77;
  background-color: white;
  margin: 5% 0%;
  border: 1px solid #482c77;
}

.ant-btn[disabled],
.ant-btn[disabled]:hover,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:active {
  /* width: 30%; */
  float: right;
}

.confirmation-disable .ant-btn[disabled],
.confirmation-disable .ant-btn[disabled]:hover,
.confirmation-disable .ant-btn[disabled]:focus,
.confirmation-disable .ant-btn[disabled]:active {
  width: 100%;
  float: right;
  border-radius: 10px;
}

.ant-modal {
  top: 50px
}

.link-input {
  margin: 2% 0% !important;
  height: 46px !important;
  background-color: #4AC6BB;
  color: white;
  border-radius: 25px !important;
}

.ant-modal-content {
  border-radius: 8px;
}

/* 
    .ant-modal {
      width: 60%;
      top: 50px;
    } */
.row-modal {
  padding: 2% 0%;
}

.popup-inner-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 35%;
  gap: 35%;
}

.popup-button {
  margin-top: 3%;
  margin-left: 5px;
  height: 40px;
  width: 95%;
  border-radius: 10px;
  color: #65737E;
  border-color: #DCD6E9;
  font-weight: bold;
}

.modal-margin {
  top: 40%
}

.modal-margin-after {
  top: 25%
}

.radio-button-1 {
  width: 14rem;
  margin: 5px 10px 5px 0px;
  border-radius: 20px;
  text-align: center;
  height: auto;
}

.radio-button-2 {
  width: 14rem;
  margin: 5px 0px 5px 10px;
  text-align: center;
  height: auto;
}

.ant-radio-button-wrapper {
  color: #65737E;
  border-color: #DCD6E9;
  min-height: 36px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #482c77;
  text-shadow: 0 0 #482C77;
}

.ant-modal-content .ant-modal-footer {
  display: none;
}

.header1 {
  align-self: stretch;
  background-color: #482c77;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 1px;
  gap: 1px;
  max-width: 100%px;
  text-align: left;
  font-size: 14px;
  color: #fff;
  font-family: Roboto;
}

.slider-icon {
  max-height: 100%;
  width: 800px;
  overflow: hidden;
  object-fit: cover;
}

.container3 {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  align-items: center;
  justify-content: center;
}

.div9 {
  background-color: #b6b6b6;
  width: 800px;
  height: 1px;
}

.yes-content-modal .ant-modal-body {
  line-height: 2.7;
}

.drawer-home .ant-drawer-content-wrapper {
  height: 580px !important;
}

.property-drawer .ant-drawer-content-wrapper {
  height: 400px !important;
}

.covid-drawer .ant-drawer-content-wrapper {
  height: 600px !important;
}

.notification-before .ant-drawer-content-wrapper {
  height: 400px !important;
}

.drawer-margin .ant-drawer-content-wrapper {
  height: 200px !important;
}

.modal-popup .ant-modal-body {
  height: 35rem;
  overflow: scroll;
}

.modal-popup {
  top: 7% !important;
}

.card-content-1 .ant-card-body {
  display: flex;
  justify-content: space-between;
  padding: 15px;
  border-radius: 10px;
}

.card-content-1 {
  border-radius: 10px;
  width: 33%;
}

/* .ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  background: #364d79;
  overflow: hidden;
} */

.css-zmy1bi {
  overflow-x: scroll;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.css-highlight::selection {
  background: red;
}

.slick-list {
  border-radius: 30px;
}

.oona-home {
  padding: 0% 20%;
  margin-top: 8%;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .link-name5:hover {
    color: #482c77;
  }

  .footer1 {
    display: none;
  }
}

.home-tab .ant-tabs-tab {
  text-align: center;
}

.carousel-image .ant-image {
  width: -webkit-fill-available;
  margin-top: 20px;
}

.carousel-image .ant-image-img {
  border-radius: 20px;
}

.card-height {
  height: 150px;
}

.ant-carousel .slick-slide {
  text-align: center;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  background-color: transparent;
}

.ant-card .home-card-content {
  display: flex;
  flex-direction: row;
}

.margin-home {
  margin: 2% 0%;
}

.margin-home .ant-card-bordered {
  border-radius: 10px;
}

.col-1-margin {
  margin: 0px 5px 0px 0px;
}

.thick-font {
  text-shadow: 0 0 #3d3636;
  font-weight: bold;
}

.help-center {
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.help-center .ant-card {
  box-shadow: 0px 0px 20px 0px rgba(132, 106, 185, 0.1)
}

.promo-card {
  background-color: #482c77;
  color: white;
  margin-bottom: 20px;
}

.news-card {
  background: transparent;
  border: none;
}

.col-2-margin {
  margin: 0px 0px 0px 5px;
}

.margin-home .ant-row .ant-col {
  margin: 1.3% 0%;
}

.lead-dashboard {
  height: 160px;
}

.radio-commercial .ant-radio-button-wrapper {
  border: 1px solid #673FAE;
  border-radius: 25px;
  height: 32px;
  background: white;
  color: #673FAE;
  text-wrap: nowrap;
}

.radio-commercial {
  display: flex;
}

.ant-table-thead>tr>th,
.ant-table-tbody>tr>td,
.ant-table tfoot>tr>th,
.ant-table tfoot>tr>td {
  padding: 7px 25px;
  text-align: start
}

.ant-table-thead>tr>th {
  background: #482C77;
  color: white;
}

.help-icons-align {
  display: flex;
  justify-content: center;
}

.ant-table-tbody>tr>td {
  border-bottom: 1px solid lightgray;
}

.ant-table-container table>thead>tr:first-child th:first-child {
  border-top-left-radius: 5px;
}

.row-inner-commercial {
  margin: 2% 0%;
}

.ant-collapse {
  border: none;
  background-color: white;
}

.helping-center-accordian {
  border: 1px solid lightgray;
}

.helping-center-accordian .ant-collapse-content {
  border: none;
}

.row-inner-commercial .ant-card {
  border-radius: 6px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
  border-top-right-radius: 5px;
}

.ant-table-pagination-right {
  justify-content: center;
}

.ant-radio-button-wrapper:not(:first-child)::before {
  background-color: transparent !important;
}

.performance-inner-font {
  font-size: 18px;
  font-weight: bold;
}

.font-performance {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0px;
  inline-size: max-content;
}

.property-font {
  font-size: 22px;
}

.ant-select-arrow {
  color: #4AC6BB;
  font-size: 15px;
}

.product-main-content {
  padding: 5% 20%;
}

.input-img-1 {
  width: 36px;
  margin-left: 7px;
}

.progress-learn-center-1 .ant-progress-circle-trail {
  stroke: #877e7e;
}

.progress-learn-center-1 .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: white;
}

.progress-learn-center-card .ant-progress-circle-trail {
  stroke: white;
}

.progress-learn-center-card .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #482C77;
}

.travel-accord .ant-collapse-content {
  border-bottom: 1px solid lightgray;
}

@media screen and (max-width: 998px) and (min-width: 600px) {
  .oona-home {
    padding: 2% 5% !important;
  }

  .main-content-body {
    padding: 2% 5% !important;
  }

  .oona-tab-nav {
    padding: 0%;
  }

  .link-name6 {
    font-size: 12px;
  }

  .footer-white-font {
    padding: 3% 0%;
    font-size: 10px;
  }
}

.footer-white-font-2 {
  font-size: 13px;
  color: white;
}

.news-card .ant-card-body {
  padding: 5px;
}

.card-inner-margin {
  box-shadow: 0px 0px 20px 0px rgba(132, 106, 185, 0.1)
}

.news-card .ant-image {
  width: -webkit-fill-available;
}

.remove-commercial-popup {
  top: 150px
}

.button-filter {
  margin-left: -9px;
  width: 38px;
}

@media screen and (max-width: 600px) {
  .footer-main-content {
    display: none;
  }

  .col-2-margin {
    text-wrap: nowrap;
  }


  .col-1-margin {
    text-wrap: nowrap;
  }


  .mobile-lead-card {
    inline-size: -webkit-fill-available;
  }

  /* .ctpl-card .ant-image-img {
    width: 64% !important;
  } */


  .button-filter {
    margin-left: 0px;
  }

  .font-card-commercial {
    padding: "0% 2% 0% 0%";
  }


  .ant-table-thead>tr>th,
  .ant-table-tbody>tr>td,
  .ant-table tfoot>tr>th,
  .ant-table tfoot>tr>td {
    padding: 10px 30px;
  }

  .input-sales-width {
    width: 96%;
  }

  .input-img-1 {
    width: 36px;
    margin-left: 0px;
  }

  .travel-card-background {
    background-color: transparent;
    /* height: 55rem; */
  }

  .home-class {
    padding: 5px;
    background: transparent;
    border: none;
    border-top: 3px solid #482C77;
  }

  .performance-class {
    padding: 5px;
    background: transparent;
    border: none;
    border-top: 3px solid #482C77;
  }

  .sales-class {
    padding: 5px;
    background: transparent;
    border: none;
    border-top: 3px solid #482C77;
  }

  .account-class {
    padding: 5px;
    background: transparent;
    border: none;
    border-top: 3px solid #482C77;
  }

  .customer-class {
    padding: 5px;
    background: transparent;
    border: none;
    border-top: 3px solid #482C77;
  }


  .home-tab-nav {
    background: transparent;
    border: none;
    padding: 5px;
  }

  /* .col-1-margin {
    height: 100px;
  }

  .col-2-margin {
    height: 100px;
  } */

  .news-card .ant-image-img {
    width: 100%;
  }

  .news-card .ant-card-body {
    padding: 3px;
  }

  .sales-card .ant-card-body {
    grid-gap: 8px;
    gap: 8px;
    padding: 7px;
    height: 50px;
  }

  /* .sales-card {
    width: 200px
  } */


  .sales-scroll {
    width: 500px;
  }

  .oona-home {
    padding: 8% 3% !important;
    overflow: scroll;
    /* margin-bottom: 60px; */
  }

  .tabs-padding {
    padding: 0px 10px;
  }

  .news-card .ant-image-img {
    width: 100%;
  }

  .lead-dashboard-card {
    height: 9.5rem;
    margin-left: 5px;
  }

  .lead-dashboard-card .ant-card-body {
    padding: 9px;
  }

  /* 
    .home-tab .ant-tabs-nav {
      display: none;
    } */
  .radio-button-1 {
    text-align: center;
    font-size: 11px;
    width: 99%;
  }

  .heading-modal-content-1 {
    padding: 0% 11%;
  }

  .radio-button-2 {
    inline-size: -moz-fit-content;
    inline-size: fit-content;
    width: 99%;
    text-align: center;
    font-size: 11px;
  }
}

.home-small-font {
  font-size: 12px;
  opacity: 0.5;
  font-weight: 600;
}

.home-small-font-2 {
  font-size: 12px;
  font-weight: 400;
}

.home-small-font-1 {
  color: #5BA46A;
  ;
}

.main-notify {
  padding: 0% 20%;
  margin-top: 10% !important;
}

.back-btn {
  padding-left: 10px;
  font-weight: bold;
  padding-top: 5px;
  font-size: 14px;
}

.arrow-left {
  padding-top: 5px;
}

.page-heading {
  color: #482c77;
  font-size: 22px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 20px !important;
}

.notify-heading-bold {
  font-weight: bold;
  font-size: 16px;
}

.notify-desc {
  color: #8996a0;
  font-size: 12px;
  margin-top: 3px;
  font-weight: 500;
}

.notify-days {
  color: #8996a0;
  font-size: 12px;
  margin-top: 5px;
  margin-bottom: 10px;
}

.ant-badge-status-dot {
  width: 8px;
  height: 8px
}

.notify-card {
  border-bottom: 1px solid #86939e;
  /* margin-top: 5px; */
  margin-bottom: 15px;
}

.contact-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  color: #673FAE;
}

.back-step-button {
  width: 100%;
  margin: 5% 0%;
  height: 36px;
  color: #482c77;
  border-radius: 10px;
  border-color: #482C77;
}

.font-building-heading {
  color: #482c77;
  font-size: 18px;
  font-weight: 600;
}

.card-info {
  width: 100%;
  background-color: #DCD6E9;
  padding: 3px 5px;
  margin: 10px 0px;
  align-items: center;
  display: flex;
  grid-gap: 10px;
  gap: 10px;
}

.building-reference {
  display: flex;
  width: 60%;
  margin: auto;
  margin-top: 10%;
}

.ant-picker {
  width: 100%;
  border-radius: 3px;
  border: 1px solid #818F99;
  height: 36px;
}

.ant-input-affix-wrapper {
  border: 1px solid #818F99;
  border-radius: 3px;
  height: 38px;
  padding: 0px 13px;
}

.ant-input-number-input-wrap {
  border: 1px solid #818F99;
  border-radius: 3px;
  height: 38px;
  /* padding: 0px 13px; */
}

.card-home-header {
  margin: 5px 0px;
}

.header-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-home-header .ant-card-body {
  padding: 5px;
  background-color: #F2F2F3;
}

.card-progress {
  background-color: transparent;
  border: none;
}

.tab-inner-travel .ant-tabs-nav-operations {
  display: none !important;
}

.col-content {
  line-height: 2.5rem;
  font-weight: bold;
  min-width: max-content;
}

.ant-form-item {
  margin-bottom: 15px;
}

.progress-content {
  transform: rotate(90deg);
  margin-top: 46px;
  margin-left: -3rem;
  width: 100px;
}

.ant-progress-outer {
  width: 110%;
}

.card-mobile .ant-card-body {
  padding: 8px;
}

.number-mob-selected {
  background-color: #4AC6BB;
  color: white;
  padding: 2px 12px;
  margin-right: 5px;
  border-radius: 50px;
  height: 28px;
  width: 28px;
}

.number-mob-non-selected {
  background-color: rgb(175, 164, 164);
  color: white;
  padding: 2px 11px;
  margin-right: 5px;
  border-radius: 50px;
  height: 28px;
  width: 28px;
}

.justify-space {
  display: flex;
  justify-content: space-between;
  margin: 2% 0%;
}

.border-card-motor {
  border: 1px solid #E1E2E2;
  box-shadow: 4px 4px 16px 0px rgba(132, 106, 185, 0.08) !important;
}

.card-discount {
  background-color: #DCD6E9;
  border-radius: 10px;
  margin-top: 3%;
}

.form-padding .ant-card-body {
  padding: 10px;
}

.card-discount .ant-card-body {
  padding: 10px;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  background: #482c77;
}

.card-content .ant-card-body {
  padding: 5px;
}

.inner-line {
  line-height: 1.5;
}

.row-travel {
  padding: 7% 5% 5% 10%;
}

.ant-upload.ant-upload-select-picture-card {
  width: 100%;
}

.travel-row-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 3%;
}

.card-customer-2 {
  box-shadow: 0px 8px 20px 0px #00000014;
  border-radius: 8px;
}

.card-customer-2 .ant-card-body {
  padding: 0px;
}

.card-image-motor .ant-card-body {
  padding: 0px;
}

.ant-progress-inner {
  border: 1px solid lightgray;
}

.ant-progress-status-success .ant-progress-bg {
  background-color: #4AC6BB !important;
}

.heading-content {
  color: #482c77;
  font-weight: 600;
  font-size: 30px;
  margin-left: 5%;
}

.heading-content-payment {
  padding: 0px 10px 0px 0px;
}


.next-button-travel:hover {
  color: black;
}

.no-button {
  background-color: white;
  color: #482c77;
  border-color: #482c77;
  height: 36px;
  width: 98%;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.card-performance-padding .ant-card-body {
  padding: 15px;
}

.card-performance-padding {
  margin-bottom: 10px;
}

.no-button-1 {
  background-color: white;
  color: #673FAE;
  border-color: #673FAE;
  width: 100%;
  height: 36px;
  border-radius: 10px;
  margin-top: 40px;
  margin-bottom: 10px;
}


.no-button-2 {
  background-color: white;
  color: #482c77;
  border-color: #482c77;
  width: 100%;
  height: 36px;
  border-radius: 10px;
  margin: 10px 0px;
}

.card-background {
  background-color: #F1EDF8;
  border-radius: 15px;
}

.helping-center-accordian .ant-collapse-item .ant-collapse-header {
  display: flex !important;
  padding: 12px 16px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 0px;
}

/* .ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding-left: 0px;
} */

/* 
.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
  display: none;
} */

.ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
  flex: auto;
}

.ant-collapse {
  margin-bottom: 2%;
  border-radius: 6px;
}

.accordian-button {
  background-color: transparent;
  color: white;
  border-radius: 6px;
  margin-top: 10px;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: none;
}

.helping-center-accordian .ant-collapse-item {
  border-bottom: 1px solid lightgray;
}

.ant-collapse-content {
  border-top: none;
  margin-bottom: 10px;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
}

.accordian-image-4 .ant-collapse-content {
  margin-bottom: 0px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
  margin: auto;
  margin-right: 30px;
}

.accord-font {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  text-align: center;
  color: #482C77;
}

.accord-bottom-font {
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-right: 1px solid lightgray;
  margin-bottom: 8px;
  align-items: center;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  justify-content: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* 
.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding-left: 0px;
} */

.accordian-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

hr {
  margin: 0.5rem 0;
}

.ant-collapse-header-text .ant-card-bordered {
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.destination-heading {
  margin: 2px 0px 10px 0px;
  color: #482c77;
  font-weight: 700;
  font-size: 14px;
}

.view-detail-font {
  color: #482c77;
  font-weight: 600;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #482c77;
  border-color: #482c77;
}

.ant-checkbox-inner {
  border: 1px solid gray;
}

.ant-input {
  border-color: #818F99;
  border-radius: 3px;
  height: 36px;
}

.ant-select {
  border-color: #818F99;
  height: 36px;
  border-radius: 3px;
}


/* border: 1px solid #818F99; */
/* .ant-select:not(.ant-select-customize-input) .ant-select-selector {
 
  height: 36px;
  padding: 2px 8px;
} */

.covid-popup .ant-modal-body {
  line-height: 1.5;
}

.modal-font {
  text-align: center;
  margin: 10px 0px;
}

.covid-card .ant-card-body {
  padding: 0px;
}

.covid-popup {
  top: 10px !important
}

.additional-font {
  color: #4F5B66;
  font-weight: 600;
}

.additional-font1 {
  color: #4F5B66;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
}

.covid-popup .ant-modal-content {
  border-radius: 8px;
  /* height: 560px; */
  overflow: auto;
}

.covid-popup::-webkit-scrollbar {
  display: none !important;
}

.ant-modal-body::-webkit-scrollbar {
  display: none !important;
}

.ant-modal-wrap::-webkit-scrollbar {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
  display: contents;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgb(153 131 131 / 30%);
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
}

.covid-popup {

  -ms-overflow-style: none;

}

.covid-font {
  padding: 0px 18px;
  font-weight: bold;
}

.covid-card {
  border: none;
}

/* .covid-card .ant-image-img {
    height: 230px;
  } */

.justify-space-1 {
  display: flex;
  justify-content: space-between;
  margin: 2% 0%;
}

.card-mob {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: scroll;
  margin: 2% 0%;
  grid-gap: 10px;
  gap: 10px;
}

.card-mob .ant-card-body {
  width: 250px;
  height: 200px;
}

.row-payment {
  align-items: center;
}

.add-benefit-card .ant-card-body {
  padding: 10px;
  font-size: 12px;
  font-weight: 200;
}

.add-benefit-card {
  margin: 10px 0px;
  border-radius: 10px;
  font-size: 12px;
  font-weight: 200;
}

.benefit-inner {
  align-items: center;
}

.next-button-travel {
  background-color: #482c77;
  border-color: #482c77;
  color: white;
  border-radius: 6px;
  width: 100%;
  height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.header-card-content {
  color: #482c77;
  padding: 2% 0%;
}

.error-inner-margin {
  margin: 2% 0%;
}

.card-performance-1 {
  background-color: #F1EDF8;
  width: 95%;
  margin: 10px 5px;
  box-shadow: rgba(132, 106, 185, 0.12);
  border-radius: 10px;
}


.card-performance-3 {
  background-color: #F1EDF8;
  margin: 10px 2px;
  box-shadow: rgba(132, 106, 185, 0.12);
  border-radius: 10px;
}


.card-performance-5 {
  margin: 10px 5px;
  box-shadow: rgba(132, 106, 185, 0.12);
  border-radius: 10px;
}

.add-downline-border {
  border-left: 3px solid #4AC6BB;
  margin-bottom: 10px;
}

.font-label {
  color: #4AC6BB;
  font-size: 12
}

.arrow-addline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.downline-padding .ant-card-body {
  padding: 15px;
}

.ant-chart-inner canvas {
  height: 11.2rem !important;
}

.ant-chart-inner1 canvas {
  height: 12rem !important;
}

.performance-margin {
  margin: 2% 0%;
}

.performance-heading {
  color: #482C77;
  padding: 15px 0px;
}

.card-inner-margin .ant-card-body {
  padding: 20px;
}

.card-performance-2 {
  margin: 7px 2px;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(132, 106, 185, 0.1);
}

.ant-input[disabled] {
  color: black;
}

.card-performance-4 {
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(132, 106, 185, 0.1);
}

.card-week canvas {
  height: 354px !important;
}

.card-week1 canvas {
  height: 21rem !important;
}

.css-1bsd2g0 {
  overflow: scroll;
}

.card-form .ant-card-body {
  padding: 11px;
}

.dGmgDB {
  padding: 17px 25px !important;
  height: 130px;
}

.tree-image {
  width: 75px;
}

.policy-detail-card .ant-card-body {
  padding: 0px;
}

.policy-padding .ant-card-body {
  padding: 10px;
}

.oona-image-risk .ant-image {
  margin: -35px 10px;
}

.risk-main-div .ant-card-body {
  padding: 0px;
}

.risk-main-div .ant-image {
  width: 100%;
}


.card-image-icon .ant-card-bordered {
  border: 1px solid white;
}

.policy-detail-card {
  border-radius: 10px;
  margin-bottom: 15px;
  box-shadow: 0px 8px 20px 0px #00000014;
}


.preview-font-content {
  padding: 15px 80px;
  text-align: center;
}

.input-child Input {
  text-align: center;
}

.preview-class {
  display: flex;
  justify-content: center;
}

.font-12 {
  font-size: 12px;
  font-weight: 500;
  inline-size: max-content;
}

.font-16 {
  text-shadow: 0 0 #3d3636;
  font-size: 16px;
  font-weight: 600;
}

.table-commercial-inner {
  font-weight: 600;
}

.table-commercial-inner .ant-table-content {
  overflow: scroll;
}

.table-commercial-inner .ant-space-align-center {
  align-items: end;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  border-color: transparent;
  color: #4AC6BB;
  background-color: transparent;
}

.ant-pagination-item-active {
  background-color: #4ac6bb;
  color: white;
  border-radius: 10px;
}

.progress-learn-center .ant-progress-inner {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px !important;
}


.progress-learn-center-1 .ant-progress-inner {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px !important;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
  color: #482C77;
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
  stroke: #482c77;
}

.progress-learn-center-card .ant-progress-inner {
  width: 50px !important;
  height: 50px !important;
  font-size: 10px !important;
}

.oona-learn-card .ant-card-body {
  padding: 0px;
}

.ant-tabs-tab-btn {
  color: black !important;
  font-weight: bold;
}

.oona-learn-card {
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.learn-heading {
  color: #482c77;
  font-weight: bold;
}

.progress-learn-center-1 .ant-progress-text {
  color: white !important;
  font-weight: bold;
}

.ant-progress-circle .ant-progress-text {
  color: #482c77;
  font-weight: bold;
}

.popup-country-list {
  top: 20% !important
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
  border-right: 1px solid lightgray;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th {
  border: 0.5px solid black;
  font-weight: bolder;
}

.button-commercial {
  color: #4ac6bb;
  width: 100px;
  border-color: #4ac6bb;
  border-radius: 4px;
  height: 30px;
  line-height: 0.5px;
}

.ant-picker-suffix {
  color: #4ac6bb;
}

@media screen and (min-width:998px) {
  .tab-inner-travel .ant-tabs-tab {
    min-width: 15.8rem;
  }

  .height-performance {
    height: 270px
  }

  .tab-inner-performance .ant-tabs-tab {
    min-width: 20rem;
  }

  .tab-inner-learn .ant-tabs-tab {
    min-width: 12rem;
  }
}

@media screen and (max-width:600px) {
  .inner-tree-img {
    padding: 5px;
  }

  .activity-tracker-inner-card .ant-card-body {
    padding: 10px 10px;
  }

  .height-performance {
    height: 150px
  }

  .main-notify {
    margin-top: 25% !important;
  }


  .payment-back-content {
    display: none;
  }

  .tab-inner-travel .ant-tabs-tab {
    min-width: 135px;
  }

  .tab-inner-performance .ant-tabs-tab {
    min-width: 135px;
  }

  .tab-inner-learn .ant-tabs-tab {
    min-width: 135px;
  }

  .margin-home {
    padding: 10px 0px;
  }
}

.profile-card {
  border-radius: 5px;
}

.profile-name {
  font-size: 24px;
  font-weight: bolder;
  color: #482c77;
  text-shadow: 1px 0px #482c77;
}

.details-card-int {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-top: 10px;
}

.btn-read {
  border-radius: 50px;
  border-color: #673FAE;
  color: #673FAE;
  float: right;
  font-size: 10px;
  font-weight: 600;
  margin-top: 0px;
}

.icon .anticon svg {
  margin-top: -5px;
}

.details-card-fin {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.profile-desc {
  color: #8996a0;
  font-size: 11px;
}

.gold-img .ant-image {
  float: right;
}

.det-head {
  color: #7e8a93;
  font-size: 10px;
  font-weight: 600;
  margin-top: 3px;
}

.det-head-bank {
  color: #7e8a93;
  font-size: 10px;
  font-weight: 600;
  margin-top: 5px;
}

.det-desc {
  /* margin-bottom: 10px; */
  font-size: 12px;
}

.details-card-int .ant-card-body {
  padding: 15px 20px;
}

.details-card .ant-card-body {
  padding: 15px 20px;
  height: 48.5px;
}

.details-card-fin .ant-card-body {
  padding: 15px 20px;
}

.sec-headings {
  font-weight: 600;
  font-size: 14px;
  margin-bottom: 5px !important;
  color: black;
}

.sec-card {
  border-radius: 5px;
  margin-top: 10px;
}

.sec-first-border {
  border-bottom: 1px solid #E1E2E2;
  padding-bottom: 10px;
}

.sec-border {
  border-bottom: 1px solid #E1E2E2;
  padding-bottom: 10px;
  padding-top: 10px;
}

.sec-last-border {
  padding-bottom: 10px;
  padding-top: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px !important;
  cursor: pointer;
  font-size: 25px;
  font-weight: 600;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
}

a {
  text-decoration: none !important;
}

.card-background .ant-input-affix-wrapper>input.ant-input {
  text-align: center;
  color: #140d0d;
  font-weight: bold;
}

.chat-bot {
  display: flex;
  justify-content: flex-start;
  margin: 0 !important;
  position: fixed;
  right: 16px;
  bottom: 100px;
  border-radius: 40px;
  background-color: #482c77;
  flex-direction: row;
  padding: 16px;
  align-items: flex-start;
  z-index: 10;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.134);
}

.success-content {
  padding: 5% 10%;
}

.commercial-agent-transfer-card {
  background-color: #21153A;
  color: white;
  margin-bottom: 10px;
  border-radius: 10px;
}

.sales-modal-inner {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  border-bottom: 1px solid lightgray;
  font-weight: bold;
}

.sales-modal-inner1 {
  display: flex;
  justify-content: space-between;
  padding: 15px 0px;
  font-weight: bold;
}

.right-icon-commercial {
  align-items: center;
  display: flex;
  justify-content: center;
}

.commercial-agent-transfer-card .ant-card-body {
  padding: 15px;
}

.preview-content {
  padding: 5% 10%;
  align-items: center;
}

.need-content {
  border: 1px solid #482c77;
  border-radius: 20px;
  padding: 2px 6px;
  color: #482c77;
  min-inline-size: max-content;
}

.ant-progress-success-bg,
.ant-progress-bg {
  background-color: #4AC6BB
}

.ant-tabs-nav-more {
  display: none;
}

.ant-tabs-ink-bar {
  background: #482c77;
}

.payment-card {
  border: 1px solid lightgray;
  border-radius: 6px;
  margin: 2% 0%;
  align-items: center;
}

.payment-card .ant-card-body {
  padding: 15px;
}

.error-content {
  padding: 0% 4%;
  text-align: center;
}

.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active {
  border-color: #4AC6BB;
  font-weight: 600;
}

.ant-form-item-label label {
  font-weight: 600 !important;
  font-size: 14px;
}

.ant-radio-button-wrapper:first-child:last-child {
  border-radius: 8px;
}

.back-dashboard {
  text-align: center;
  margin-top: 10px;
  cursor: pointer;
}

.bold-margin {
  margin: 10px 0px;
  padding-right: 25%;
}

.space-letter {
  letter-spacing: 1px;
}

.content-form-dashboard {
  inline-size: max-content;
}

::-webkit-scrollbar {
  display: none;
}

.anticon-arrow-left {
  color: #482c77;
  font-size: 15px;
}

.arrow-back-font {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px;
  font-weight: bold;
  color: #482c77;
  cursor: pointer;
}

.ant-tooltip-inner {
  background-color: #f68b1f;
  border-radius: 6px;
  font-size: 11px
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #f68b1f;
}

.row-card-content .ant-image-img {
  width: 50%;
}

.row-card-content .ant-card-body {
  padding: 15px;
}

.ctpl-card .ant-image-img {
  width: 100%;
}
.product-card{
  color: black;
  text-align: center;
  width: 98%;
  height: 80px;
  font-size: 11px;
  cursor: pointer;
  position: relative;
}
.product-card .ant-image-img{
  width:100%;
}
.product-inner-cnt{
  display: flex;
  align-items: center;
}
.product-thick-font{
  color: #5C3899;
  font-size: 14px;
  font-weight: 600;
}
.flag-cnt{
  background: #482C77;
  color: white;
  width: auto;
  Padding:4px 8px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 500;
  height: 25px;
  position:absolute;
  z-index: 3;
  top: -15px;
  left: 20px;
}
@media screen and (max-width:600px) {
  .flag-cnt{
    font-size: 8px;
    height: 15px;
    top: -5px;
    Padding:2px 8px;
  }
}

.row-card-content1 .ant-card-body {
  padding: 15px;
}

.ctpl-card {
  background-color: #FBE8B5;
  color: black;
  text-align: center;
  width: 98%;
  font-size: 11px;
  cursor: pointer;
}

.motor-card {
  background-color: #f68b1f;
  color: white;
  text-align: center;
  width: 98%;
  font-size: 11px;
  cursor: pointer;
}

.travel-card {
  background-color: #482c77;
  color: white;
  text-align: center;
  width: 98%;
  font-size: 11px;
  cursor: pointer;
}

.home-card {
  background-color: #4ac6bb;
  color: white;
  text-align: center;
  width: 98%;
  font-size: 11px;
  cursor: pointer;
}

.home-image-card {
  width: 75%;
  padding-left: 50px;
}

.ant-tooltip {
  z-index: 0 !important;
}

.sales-card-col-1 {
  align-items: end;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  padding-left: 5;
}

.sales-card-col-2 {
  align-items: end;
  display: flex;
  grid-gap: 6px;
  gap: 6px;
  justify-content: end;
  padding-right: 5px;
}

.commercial-card {
  background-color: #C8CDD1;
  color: white;
  text-align: center;
  width: 98%;
  font-size: 11px;
  height: 85px;
}

.commercial-lead-card .ant-card-body {
  padding: 10px;
}

.link-name6 {
  font-weight: bold;
  text-shadow: 0 0 #3d3636;
}

.card-products {
  margin-bottom: 10px;
  border-radius: 6px;
}

.ant-carousel .slick-dots-bottom {
  bottom: -30px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #482C77;
}

.ant-carousel .slick-dots li button {
  background: darkgray;
}

.logout-image {
  width: 45%;
}

.single-line-text {
  inline-size: max-content;
}

.bronze-background {
  padding: 0px 15px;
  background-color: #CD7F32;
  /* background-image: url("./oonaImages/bronze.png"); */
  color: white;
  border-radius: 3px;
  justify-content: flex-end;
  text-align: center;
}

/* .ant-select-selection-overflow {
  flex-wrap: nowrap;
  overflow: auto;
} */


@media screen and (max-width:600px) {
  .link-name6 {
    font-size: 9px;
  }


  .select-product {
    font-size: 20px;
    font-weight: 700;
    color: #343D46;
  }


  .nav-icon5 {
    width: 70%;
  }

  .home-image-back {
    background: transparent;
  }

  .ctpl-card {
    height: 100px;
  }

  .motor-card {
    height: 100px;
  }

  .travel-card {
    height: 100px;
  }

  .home-card {
    height: 100px;
  }

  .col-1-margin {
    margin: 0px 0px 0px 0px;
  }


  .home-image-card {
    margin-left: 30px;
    padding-left: 50px;
    margin-top: -50px;
    width: 75%;
  }

  .oona-tab-nav {
    padding: 0%;
    bottom: -3px;
    position: fixed;
    background-color: white;
    width: 100%;
  }

  .row-travel {
    padding: 0% !important;
  }

  .row-travel-3 {
    padding: 0% !important;
  }

  .motor-back-image-space {
    margin-bottom: 12rem;
  }

  .motor-image-mob {
    background-image: url("/app/static/media/Group 1000004221.d96bd991.png");
    background-size: 100% 100%;
  }

  .row-travel-1 {
    padding: 0% !important;
  }

  .row-travel-4 {
    padding: 0% !important;
  }

  .row-travel-2 {
    padding: 0% !important;
  }

  .success-content {
    padding: 3% !important;
    text-align: center;
  }
      
      
  .payment_screen{
    margin: 7em 1em !important
  }

  .back-content {
    display: none !important;
  }

  .mob-header {
    font-size: 17px;
    font-weight: bold;
    color: #482c77;
  }

  .need-content {
    height: 28px;
  }
}

@media screen and (max-width:600px) {
  .row__aob {
    padding: 0% !important;
  }

  .next-button-oona {
    background-color: #482c77 !important;
    border-color: #482c77 !important;
    color: white;
    border-radius: 6px;
    width: 100% !important;
    height: 36px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.row__dashboard {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard__title {
  font-size: 25px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #482C77
}

.alert__mesaage__tag {
  background-color: #F68B1F;
  border-radius: 8px;
  color: #FFFFFF;
  display: flex;
  padding: 0.5%;
  margin-bottom: 1rem;
}

.alert__mesaage__tag {
  /* background-color: yellow; */
  border-radius: 8px;
}

.profile__dashboard {
  border-radius: 8px;
}

.alert__mesaage__tag>div:nth-child(1) {
  width: 10%;
  /* background-color: red; */
}

.alert__mesaage__tag>div:nth-child(2) {
  width: 80%;
  text-align: center;
}

.alert__mesaage__tag>div:nth-child(3) {
  width: 10%;
  /* background-color: red; */
}

.arrow__left__outlined__customer {
  width: 7%;
  /* background-color: yellow; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product__title__slider {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #818F99;
  width: 100%;
  color: #18191B;
  font-size: 0.9em;
  font-weight: 800;
}

.product__title__right {
  padding: 4px 8px 4px 8px;
  border-radius: 4px;
  grid-gap: 8px;
  gap: 8px;
  background-color: #4AC6BB;
  color: #FFFFFF
}

.dashboard__context {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.profile__dashboard,
.customerDetails__personalInfor {
  margin-bottom: 1rem;
  /* background-color: yellow; */
  border-radius: 15px;
}

.icons__customerDetails__message {
  /* background-color: red; */
  display: flex;
}

.dashboard__context {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.dashboard__title {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000
}

.dashboard__subtitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #818F99
}

.idcard__title {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #818F99
}

.idCard__subtitle {
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #272D33
}

.icons__customerDetails__call {
  /* background-color: yellow; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons__customerDetails_email {
  /* background-color: blue; */
  display: flex;
  justify-content: flex-end;
}

.icons__footer__customerDetails {
  padding: 12px 24px 12px 24px
}

.icons__header__customerDetails {
  padding: 0px 20px 0px 20px
}

.icons__customerDetails__message__text {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #482C77;
}

.customerDetails__personalInfor {
  display: flex;
  flex-direction: column;
}

.customerDetails__personalInfor__cols {
  display: flex;
  justify-content: space-between;
}

.customerDetails__personalInfor__cols__text {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #272D33;
}

.customerDetails__personalInfor__cols__logo {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customerDetails__title {
  width: 28%
}

.customerDetails__personalInfor__cols__details {
  width: 80%;
  padding: 2%;
}

.customerDetails__personalInfor__cols__Image {
  width: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.customerDetails__personalInfor__cols__details__premium {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.customerDetails__personalInfor__cols__details__premium div:nth-child(1) {
  width: 27%;
}

.customerDetails__personalInfor__cols__details__premium div:nth-child(2) {
  width: 27%;
}

.row__dashboard__page {
  padding: 3% 0% 3% 0%
}

@media only screen and (min-width: 601px) and (max-width: 768px) {
  .arrow__left__outlined {
    width: 17%
  }

  .aob__register .tab-inner-travel .ant-tabs-tab {
    min-width: 11rem !important;
  }
}

@media only screen and (min-width: 376px) and (max-width: 425px) {
  .oona-mob-selected {
    margin-right: 11px !important;
  }

  .oona-mob-non-selected {
    margin-right: 11px !important;
  }

  .customer__age__notification__inside div:nth-child(2) span {
    font-size: 10px;
    display: inline-block;
    width: 140px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 320px) {
  .customer__age__notification__inside div:nth-child(2) span {
    font-size: 10px;
    display: inline-block;
    width: 140px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 321px) and (max-width: 375px) {
  .oona-mob-selected {
    margin-right: 7px !important;
  }

  .oona-mob-non-selected {
    margin-right: 7px !important;
  }

  .customer__age__notification__inside div:nth-child(2) span {
    font-size: 10px;
    display: inline-block;
    width: 140px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }
}

.customer__age__notification {
  background-color: #F68B1F;
  padding: 0.1%;
  border-radius: 0.5rem;
}

.customer__age__notification__inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem
}

.customer__age__notification__inside div:nth-child(1) {
  color: #FFFFFF;
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
}

.customer__age__notification__inside div:nth-child(2) {
  color: #FFFFFF;
  width: 90%;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 500;
}

.customer__age__notification__inside div:nth-child(2) span {
  font-weight: 600;
  margin-left: 5px;
  font-size: 12px;
}

.footer-background {
  background: #482C77;
}

.footer-white-font {
  color: white;
  padding: 3% 0%;
}

.footer-button-2 {
  background-color: transparent;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  height: 36px;
}

.col-footer-line {
  border-right: 1px solid white;
  text-align: center;
}

.col-footer-line1 {
  text-align: center;
}

.footer-white-font-1 {
  color: lightgray;
  padding: 3% 2%;
}

.footer-button-1 {
  background-color: #4AC6BB;
  border-color: none;
  color: white;
  border-radius: 8px;
}

@media screen and (max-width: 620px) {
  .ant-tabs-nav {
    border-bottom: 1px solid lightgray;
    box-shadow: none !important;
  }

  .travel-back-card-mob .ant-card-body {
    padding: 10px
  }

  .travel-back-card-mob {
    min-height: 46rem;
  }
}

.footer-main-content {
  background-color: #482C77;
}

@media screen and (min-width: 1400px) {
  .tab-inner-travel .ant-tabs-tab {
    min-width: 19.8rem;
  }
}


.accordian-image__Par {
  /* background-image: url("./oonaImages/Frame\ 1000004288\ \(1\).png"); */
  background-size: 100% 100%;
  color: white;
  background: linear-gradient(74.62deg, #6F5000 -10.07%, #D1A737 99.04%);
}


.disabled-row {
  background-color: #F1F0F5;
  pointer-events: none;
  color: #A3ACB2;
  font-size: 14px;
  font-weight: 500
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table {
  border-left: 1px solid lightgray;
}

.button-resignedcommercial {
  border: 1px solid #BB251A;
  color: #BB251A;
  border-radius: 4px;
  font-weight: 600;
  width: 100px;
  opacity: 0.6;
  height: 30px;
  line-height: 0.5px;
}

.text__color__aob {
  font-weight: 500;
  font-size: 12px;
  color: #BB251A
}

.otp__error__message__text {
  font-weight: 500;
  font-size: 14px;
  color: #BB251A
}

.otp__error__message {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}

.risk-font-1 {
  margin: 15px 0px;
  font-weight: 600;
}

.red-star {
  color: red;
  margin: 0px 6px;
}

.proceed-risk-button {
  background-color: #482C77;
  display: flex;
  align-items: center;
  color: white;
  border-radius: 10px;
  height: 36px;
}

.card-risk-content {
  margin: 10px 10px 10px 0px !important;
  border-radius: 10px;
  text-align: center;
}

.back-risk-button {
  background-color: white;
  display: flex;
  align-items: center;
  color: #482C77;
  border-radius: 10px;
  height: 36px;
}


.proceed-risk-button:active {
  background-color: white;
  display: flex;
  align-items: center;
  color: #482c77;
  border-radius: 10px;
  height: 36px;
}



.bar-height {
  height: 335px !important;
}

.my-team-bar-1 {
  height: 210px !important;
}

.my-team-bar {
  height: 210px !important;
}


@media screen and (min-width:1400px) {
  .graph-left-card {
    height: 100px;
  }

  .team-performance-bar {
    height: 425px;
  }

  .graph-right-card {
    height: 330px;
  }

  .graph-right-card-1 {
    height: 330px;
  }

  .tab-inner-performance .ant-tabs-tab {
    min-width: 26rem;
  }

  .bar-height {
    height: 335px !important;
  }

  .my-team-bar-1 {
    height: 235px !important;
  }

  .my-team-bar {
    height: 235px !important;
  }
}


@media screen and (min-width:1000px) and (max-width:1399px) {

  .graph-right-card {
    height: 320px;
  }

  .graph-right-card-1 {
    height: 320px;
  }

  .team-performance-bar {
    height: 425px;
  }

  .bar-height {
    height: 340px !important;
  }

  .my-team-bar-1 {
    height: 235px !important;
  }

  .my-team-bar {
    height: 235px !important;
  }
}


@media screen and (min-width:601px) and (max-width:999px) {

  .graph-right-card {
    height: 290px;
  }

  .graph-left-card {
    height: 87px;
  }

  .graph-right-card-1 {
    height: 320px;
  }

  .team-performance-bar {
    height: 425px;
  }

  .bar-height {
    height: 335px !important;
  }
}

.risk-inspection-div {
  display: none;
}

.oona__motor__transfer {
  display: flex;
  justify-content: space-between;
  padding: 16px 0px 0px 0px;
}

.ant-slider-handle {
  border: solid 2px #673FAE;
  background-color: #673FAE;
}

.ant-slider-track {
  background-color: #482C77;
}

.home__policy .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}


.video-container {
  position: relative;
  width: 100%;
  /* height: 100vh; */
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.oona-travel-otp {
  padding: 10% 25%;
}

.travel-accord .ant-collapse-content {
  margin-left: 12px;
}

.home-accord .ant-collapse-header {
  padding-left: 0px !important;
}

.ant-input-number {
  width: 100%
}

.input-style {
  padding: 6px;
  width: 100%;
  border: 1px solid lightgray;
}

.ant-drawer-content {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.displayed_Image_middle {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.oona__image__height {
  width: 300px;
  height: 200px;
}

.reject-image {
  position: relative;
}

.reject-image::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  opacity: 0.5;
  z-index: 1;
}

.reject-image img {
  width: 100%;
  height: auto;
  z-index: 2;
}

.reject-image p {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  margin: 0;
}

.card-content {
  position: relative;
}

.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  color: white;
  font-size: 24px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1;
}

.card__logo {
  width: 200px;
  background-color: yellow;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 30vh
}


.searchBox {
  width: 100%
}

.searchBox input {
  width: 94.50%
}

.searchBox>div:nth-child(1) {
  width: 95.50% !important
}

.maps__address {
  display: flex;
  /* justify-content: space-between; */
  /* justify-content: center; */
  /* align-items: center; */
  /* justify-content: ; */
  width: 100%;


}

/* .maptestbox {
  width: 25%;

} */

.button-map {
  margin-left: 10px;
  width: 100%;
  background-color: #482c77;
  border-radius: 10px;
  color: white;
  height: 36px;
}

.mapresultbox {

  width: 70%;
}

.mapspointer:hover {
  background-color: #fff;
}

/* .ant-select-dropdown {
  overflow: auto;
} */

.header__padding {
  margin-bottom: 2em;
}


.oona__customer__flow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arra__top {
  margin-top: 1rem
}

.articleFont {
  font-size: 0.8rem !important;
}

.definitionFont {
  font-size: 0.7rem !important;
}

.newWithOUtLInk {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
}

input[type="file"] {
  display: none;
}

label {
  cursor: pointer;
}

#imageName {
  color: green;
}

.Profile__image {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* personal accident */
.anticon-info-circle {
  position: relative;
  top: 1px;
  font-size: 16px;
  color: #482c77;
}

.next-button-pa {
  background-color: #482c77;
  height: 40px;
  width: 100%;
  color: white;
  font-weight: 600;
  font-size: 14px;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .next-button-pa {
    background-color: #482c77;
    height: 40px;
    width: 100px;
    color: white;
    float: right;
    border-radius: 10px;
    margin: 10% 0 25% 0;
    font-weight: 600;
    font-size: 14px;
  }
}

.modal-sub-header-cnt {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

/* popover responsive */
@media screen and (max-width: 768px) {
  .anticon-info-circle {
    position: relative;
    top: 1px;
    font-size: 12px;
    color: #482c77;
  }

  ul>li {
    font-size: 11px;
    font-weight: 550;
  }

  .ant-popover-inner {
    width: 250px;
    height: 120px;
  }

  .ant-popover-inner-content {
    padding: 5px 9px;
  }

  .check-cnt {
    font-size: 12px;
  }

  .modal-sub-header-cnt {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;
  }
}

.ant-checkbox+span {
  font-weight: 600;
}

.ant-popover-inner {
  background: rgba(0, 0, 0, 0.5);

}

.ant-popover-inner-content {
  color: white;
  padding: 9px 16px;
}

.ant-popover-arrow .ant-popover-arrow-content {
  background: rgba(0, 0, 0, 0.5) !important;
}

.ant-popover-arrow-content::before {
  background: none;
}

/* .ant-input[disabled]{
  text-align: center;
  font-weight: bold;
} */
.card-d-flex {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.card-d-flex-content {
  color: #482c77 !important;
  padding: 2% 0% !important;
}

/* scrollbar */
.ant-select-item-option-content {
  max-height: 400px;
  overflow-y: scroll;
}

.card-d-flex-content {
  color: #482c77;
  padding: 2% 0%;
}

.card-button {
  margin-top: 3%;
  margin-left: 5px;
  height: 40px;
  width: 95%;
  border-radius: 10px;
  font-weight: bold;
  border: 1px solid #65737E;
}

.card-button:hover {
  color: black;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: #4AC6BB;
  font-weight: bold;
  font-size: 14px;
}

.customerinfo-input {
  text-align: left !important;
}

.card-shadow {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 3px;
}

.letter-space {
  letter-spacing: 0.98px;
}

.accident-user-icon {
  width: 32px;
  height: 32px;
}

.d-flex-content {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-top: 4px;
  width: 80%;
}

.info-font {
  color: #4F5B66;
  font-weight: 400;
  line-height: 21px;
  padding: 3px;
}

.anticon .anticon-check-circle {
  width: 32px;
  height: 32px;
  color: green;
}

.accident-background {
  background-size: 100% 100%;
  color: white;
  background: #482C77;
}

.total-premium {
  font-size: 14px;
  font-weight: 600;
}

.total-rp {
  font-size: 14px;
  font-weight: 600;
  padding: 0px 65px 15px 0px;
}

ul>li {
  font-weight: 550 !important;
}

@media screen and (max-width: 768px) {
  .total-rp {
    font-size: 14px;
    font-weight: 600;
  }

  .total-premium {
    font-size: 14px;
    font-weight: 600;
  }
}

.accident-margin {
  top: 40%;
  height: 370px;
}

.accident-margin-after {
  top: 25%
}

.bold-promo-cnt {
  padding: 5px 0 10px 0;
  font-size: 14px;
}

.label-font {
  font-size: 14px;
  font-weight: 550;
}

.label-font-1 {
  font-size: 14px;
  font-weight: bolder;
}

.card-button-1 {
  margin-top: 3%;
  height: 40px;
  width: 100%;
  border-radius: 10px;
  font-weight: bold;
}

.modal-body-cnt {
  height: 500px;
}

.card-button-pa {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 3%;
  height: auto;
  width: 100%;
  padding: 0 15px;
  border-radius: 10px;
  font-weight: bold;
  border: 1px solid #65737E;
  box-sizing: border-box;
}

.ant-collapse-content-pa .ant-collapse-content {
  border: none !important;
}

.ant-collapse-content-pa .ant-collapse-content>.ant-collapse-content-box {
  padding: 0;
}

.ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
  color: #4AC6BB;
  transform: rotate(90deg);
  font-size: 14px;
}

.ant-collapse-content-pa .ant-collapse-icon-position-end>.ant-collapse-item>.ant-collapse-header {
  display: flex;
  align-items: center;
}

.ant-collapse-icon-position-end>.ant-collapse-item-active>.ant-collapse-header .ant-collapse-arrow {
  transform: rotate(180deg);
  font-size: 14px;
}

.selectlimit-pa {
  margin-bottom: 0;
}

.radio-group {
  width: 100%;
}

.card-d-flex-pa {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 40px;
  white-space: normal;
  /* Allow text to wrap */
}

.card-d-flex-pa>div {
  flex-grow: 1;
  /* Allow content to grow */
}

.card-d-flex-pa .ant-radio-wrapper {
  margin-left: 15px;
  /* Adjust as needed */
}

.benefit-inner-notes {
  text-align: center;
}

.radio-print-policy {
  display: flex;
  flex-direction: row;
  align-content: flex-end;
  align-items: flex-end;
  justify-content: flex-end;
}

.radio-print-policy span.ant-radio+* {
  padding-right: 0px !important;
}

.hardCopy__softCopy{
  display: flex;
  justify-content: space-between;

}

.hardCopy__softCopy :nth-child(2){
  margin-left:1em
}	
.addressForHardcopy{
  width:50%;
  overflow-wrap: break-word;
  text-align: end;

}
.copy__space{
  display: flex;
}
.copy__space .ant-image-img{
  width: 60% !important;
  margin-left: 0.5em !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.row__aob__inside .ant-select:not(.ant-select-customize-input) .ant-select-selector{
  border-color: #818F99 !important;
  height: 36px;
  border-radius: 3px;
}
/* .ant-select-item-option-content {
  white-space: normal;
  word-break: break-word;
} */

.ant-select-item-option-content {
  white-space: normal !important;
  word-break: break-word !important;
}
.slider__multiline .ant-slider-mark-text {
  white-space: nowrap;
}

.product__title{
  font-size: 0.9em;
  color:#18191B;
  font-weight: 800;
}
.slider__multiline .ant-slider-track{
  height: 9px !important;
}
.nestedCollapse .ant-collapse-content {
  border-left: none !important;
  border-right: none !important;
}

.slidertextAlign {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.textSection {
  color: #818F99;
  font-weight: 500;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.linkSection {
  color: #5C3899;
  font-size: 0.8em;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.5em; 
}

@media (max-width: 768px) {
  .textSection {
    font-size: 0.9em; 
  }
  .linkSection {
    font-size: 0.8em; 
  }
  .badgeforFreetag{
    width:90% !important
  }
  /* .next-button-travel {
      position: relative;
      width: 100%;
      margin-top: 20px;
  } */

   .buttons_alignments{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
   }
   .text__align{
    text-align: center !important;
    padding:9px 0px !important
   }

}


.badgeforFreetag{
  color:white;
  background-color:#4AC6BB;
  padding:4px 8px 4px 8px;
  border-radius:4px;
  width: 34%;
  margin-left: 0.5em;
  font-size: 0.8em;
}

.panel__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color:#482C77
}

.panel__header .ant-collapse-arrow {
  order: 1; /* Move the arrow to the end */
  margin-left: auto; /* Ensure it is pushed to the right */
}

.buttons_align{
  display: flex;
 align-items: center;
 justify-content: space-between;
}

.buttons_alignments{
  display: flex ;
  justify-content: space-between ;
  align-items: center ;
 }

 .applyingdiscountColor{
  color:#C65143;
  font-weight: 500;
  font-size: 1.2em;
 }

 .applyingwithoutdiscountColor{
  font-weight: 500;
  font-size: 1.2em;
 }

.agentHeader{
  height: "70px";
  display:"flex";
  align-items:"center";
  position:"fixed";
  top:"0";
  width:"100%";
  z-index:"1000"
}

@media (max-width: 400px) {
  .agentHeader{
    height: auto !important;
    flex-direction: column !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
.payment_screen{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5% 2%;
}






.loader_data{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(181 174 174 / 81%);
    z-index: 100;
}
.loader{
    left: 45%;
    top: 45%;
    z-index: 1000;
    position: absolute;
}
.main-content-body {
    padding: 5% 18%;
}

.heading {
    font-size: 32px;
    font-weight: bold;
    color: #482c77;
    margin-top: 20px;
}

.ant-card-actions {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    content: none;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 50px;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 50px;
    margin-left: 0px;
}

/* 
.top-card{
    border-left: 2px solid #f79838;
} */

.ant-input-search-button {
    height: 36px;
    border-left: none;
    border-right: 1px solid #818F99;
    border-top: 1px solid #818F99;
    border-bottom: 1px solid #818F99;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
    color: #4AC6BB;
}

.ant-radio-button-wrapper {
    margin-left: 10px;
    border-radius: 50px;
    border-left-width: 1px;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-radius: 50px;
}

.leads-card .ant-card-body {
    padding: 0px;
}

.leads-card {
    border-radius: 5px;
    margin-right: 10px;
    margin-bottom: 15px;
    box-shadow: 0px 8px 20px 0px #00000014;
}

.card-header {
    border-bottom: 1px solid #f2f2f3;
    width: 138%;
}

.card-body {
    /* padding: 10px; */
    width: 138%;

}

.header-text {
    padding: 10px;

    font-size: 13px !important;
    color: #87959e;
}

.header-right {
    float: right;
}

@media screen and (max-width:600px) {
    .tab-inner-travel .ant-tabs-tab {
        min-width: 135px;
    }

    .main-content-body {
        padding: 10px 10px;
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    color: #fff;
    background: #8c5acb;
    border-color: #8c5acb;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff;
    background: #8c5acb;
    border-color: #8c5acb;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #8c5acb;
}

.ant-radio-button-wrapper:hover {
    position: relative;
    color: #fff;
}

.radio-leads .ant-radio-button-wrapper {
    color: #8c5acb !important;
    border-color: #8c5acb !important;
    height: 30px;
    line-height: 25px;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: #fff !important;
}

.ant-input-search-button .ant-radio-inner::after {
    background-color: #8c5acb;
}

.ant-radio-checked .ant-radio-inner {
    border-color: #8c5acb;
}

.action .anticon svg {
    margin-top: -5px;
    margin-right: 5px
}
.row__aob {
  padding: 5% 5% 5% 10%;
}
.oona_aob_font {
  font-family: "GeneralSans-Medium";
}
.oona_aob_font-medium {
  font-family: "GeneralSans-Medium";
  font-size: 14px;
}

.oto__bold {
  font-weight: 900;
}

.aob__register label {
  font-family: "GeneralSans-SemiBold";
}
.otp__confir label {
  font-family: "GeneralSans-SemiBold";
}

.aob__idcard label {
  font-family: "GeneralSans-SemiBold";
}
.aob__taxpayer label {
  font-family: "GeneralSans-SemiBold";
}
.withoutReferral label {
  font-family: "GeneralSans-SemiBold";
}
.aob__register .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-family: "GeneralSans-SemiBold";
}
.row__aob__inside {
  padding: 1% 3% 1% 3%;
}
.aob__checkbox {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.oona__small__fontSize {
  font-size: 15px;
}
.oona__parent {
  margin-top: 1.5rem;
}
.aob__checkbox__web {
  display: flex;
  width: 100%;
}

.aob__checkbox__gap {
  margin-right: 10px;
}
.aob__checkbox__span {
  color: #272d33;
  font-weight: 500;
  font-size: 1em;
  text-align: start;
}
.aob__checkbox__span span {
  color: #482c77;
  font-size: 1em;
  font-weight: 600;
}

.header_card_content_description {
  color: #343d46;
  font-weight: 500;
  font-size: 1rem;
}

.upload__photo__section__mobile {
  border: 2px dotted #d9d9d9;
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 6px;
}

.upload__photo__section__web {
  border: 2px dotted #d9d9d9;
  padding: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}

.oona__aob .ant-progress-outer {
  width: 139%;
}
.oona__aob__progress__text {
  margin-bottom: 1rem;
  color: #18191b;
  font-size: 12px;
}

.terms__font {
  font-size: 25px;
}

.upload__photo__section__web__single {
  border: 2px dotted #d9d9d9;
  padding: 7%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 6px;
}

.upload__photo__section__mobile__header {
  font-weight: 600;
  font-size: 14px;
  color: #343d46;
  margin-bottom: 1%;
}

.upload__photo__section__mobile__footer {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #482c77;
}

.aob__termsandConditions__mobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.aob__termsandConditions__mobile__content {
  margin-bottom: 1rem;
}

.aob__termsandConditions__mobile__content__title {
  color: #482c77;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.aob__termsandConditions__mobile__content__description {
  color: #272d33;
  font-weight: 500;
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.aob__terms__context__space {
  margin-top: 1rem;
}

.aob__termsandConditions__mobile__content__description > div {
  margin-bottom: 0.8rem;
}

.aob__termsandConditions__web {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.aob__termsandConditions__web__content__title {
  color: #482c77;
  font-weight: 600;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.aob__termsandConditions__web__content__description > div {
  margin-bottom: 0.8rem;
}

.upload__Signature__section__mobile {
  border: 2px dotted #d9d9d9;
  padding: 17%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
  /* background-color: yellow; */
}

.upload__Signature__section__web__single {
  margin-top: 0.5rem;
  border: 2px dotted #d9d9d9;
  padding: 0%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  border-radius: 10px;
}
.upload__Signature__section__web__single > div {
  width: 100%;
  height: 100%;
}

/* .idCard__signature{
 height:100%;
width:270px;
background-color: yellow;
} */

.individualaob__modalPOpup__headerone {
  font-weight: 700px;
  font-size: 1.3rem;
  text-align: center;
  color: #000000;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

/* .individualaob__modalPOpup__headerTwo{
    display: flex;
    flex-direction: column;
    margin-bottom: 1.50rem;
    
}
.individualaob__modalPOpup__headerTwo>div{
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.3rem;
    text-align: center;
} */

.individualaob__button__row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* margin-bottom: 1rem; */
  /* height:70px; */

  /* height: 276px;
    background-color: #21153A; */
}

.drawer__oona__AOB__header__one {
  line-height: 30px;
}

.drawer__oona__AOB__header__one > div:nth-child(1) {
  font-weight: 600;
  font-size: 25px;
  /* background-color: yellow; */
  padding: 10px 6px;
  text-align: center;
  color: #000000;
}

.drawer__oona__AOB__header__one h2 {
  /* background-color: yellow; */
}

.drawer__oona__AOB__header__one > div:nth-child(2) {
  font-weight: 500;
  font-size: 14px;
  /* background-color: yellow; */
  padding: 10px 6px;
  text-align: center;
  color: #343d46;
  line-height: 21px;
}

.drawer__Coponent__oona .ant-drawer-content-wrapper {
  height: 276px !important;
}
.drawer__Coponent__oona .ant-drawer-content {
  border-top-left-radius: 12px !important;
}

.logo__suucess {
  display: flex;
}

.oonaSuccess__kahoona {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 414px;
  width: 360px;
  grid-gap: 24px;
  gap: 24px;
}

.oonaSuccess__ocrkahoona {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  width: 360px;
  grid-gap: 24px;
  gap: 24px;
}

.oonaError__kahoona {
  display: flex;
  flex-direction: column;
  height: 360px;
  width: 321px;
  grid-gap: 24px;
  gap: 24px;
}

.oonaSuccess__kahoona__mobile {
  padding: 1rem;
}

.oonaSuccess__kahoona__mobile__context {
  height: 154px;
}

.oonaSuccess__kahoona__mobile__context__header {
  font-size: 35px;
  font-weight: 600;
  line-height: 49px;
  letter-spacing: 0em;
  text-align: center;
  color: #482c77;
}
.oonaSuccess__kahoona__mobile__context__header__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  padding: 0.3rem;
}

.oonaSuccess__kahoona__header {
  font-weight: 600;
  font-size: 41px;
  line-height: 49.2px;
  color: #482c77;
  margin-bottom: 0.5rem;
  margin-bottom: -1rem;
}

.oonaSuccess__ocrkahoona__header{
  font-weight: 600;
  font-size: 41px;
  line-height: 49.2px;
  color: #482c77;
  margin-bottom: 0.5rem;
  margin-bottom: -1rem;

}
.oonaSuccess__kahoona__header__download__mobile {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}
.oonaSucess__images__mobile {
  display: flex;
  justify-content: space-between;
}
/* .oonaSucess__images__mobile>div{
    width: 129.64px;
     height: 44px

} */
.oona__success_buttons {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.oona__success_buttons > button:nth-child(1) {
  background-color: #ffffff;
  border-color: #482c77 !important;
  color: #8c5acb;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.oona__success_buttons > button:nth-child(2) {
  background-color: #482c77;
  border-color: #482c77 !important;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.oona__success_buttons__referral > button:nth-child(1) {
  background-color: #4ac6bb;
  border-color: #4ac6bb !important;
  color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 36px;
  /*margin-top: 10px;
    margin-bottom: 10px;
    float: right;
    text-align: center; */
}

.oonaSuccess__kahoona__header__cenetered {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  width: 360px;
  height: 120px;
  width:100%
}

.oonaSuccess__kahoona__header__cenetered div:nth-child(1) {
  margin-bottom: 1rem;
}
.oonaError__kahoona__header__cenetered {
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
  height: 120px;
}

.oonaSuccess__kahoona__header__cenetered > div {
  color: #18191b;
}
.oonaSuccess__kahoona__header__download {
  color: #18191b;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: -1rem;
}
.logo__suucess {
  display: flex;
  justify-content: space-between;
  width: 95%;
}
.oonna_success_buuton {
  display: flex;
  justify-content: space-between;
}

.oonna_success_buuton__home {
  /* padding:6px 16px 6px 16px  */
  background-color: #ffffff;
  border-color: #482c77 !important;
  color: #8c5acb;
  border-radius: 10px;
  width: 48%;
  height: 46px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.oonna_success_buuton__Invite {
  background-color: #482c77;
  border-color: #482c77 !important;
  color: #ffffff;
  border-radius: 10px;
  width: 48%;
  height: 46px;
  margin-top: 10px;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

/* .drawer__Coponent__oona  div:nth-child(2){
    height:276px !important
} */

/* .individualaob__button__row>button{
    width:48%;
    border:1px solid #DCD6E9 !important;
    padding:1.5rem !important;
    

} */

/* .individualaob__button{

    display: flex;
    background-color: yellow;
} */

/* .individualaob__button > button{
    width: 100%;
    padding:12px 16px 12px 16px;
    border-radius: 7px;
    border-color: #DCD6E9;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    font-size: 15px;
    } */
.oona__tab .ant-tabs-tab .ant-tabs-tab-btn {
  color: #343d46 !important;
  font-weight: 800;
}

.oona__tab .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #673fae !important;
}
.oona__tab .ant-tabs-tab {
  border-bottom: 1px solid #e1e2e2;
}

.tax__padding > h5 {
  padding: 1% 0% 1% 0% !important;
  /* background-color: yellow; */
}
.font__size__idCard {
  font-size: 23px !important;
}
.upload__photo__section__web > button {
  border: transparent;
}
.individualaob__button__row > button {
  /* background-color:#482c77; */
  border-color: #dcd6e9 !important;
  /* color: white; */
  border-radius: 12px;
  width: 48%;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "GeneralSans-Medium";
}
.oonaAob__modal__button {
  width: 600px !important;
}
.oonaAob__modal__button__header {
  width: 536px;
}
.individualaob__modal__header {
  margin-bottom: 5px;
}
.individualaob__modal__header > h5 {
  text-align: center;
  /* padding:1% 13% 1% 13%; */
  padding: 1% 16% 1% 16%;
  font-family: "GeneralSans-Medium";
  font-size: 23px;

  /* background-color: yellow; */
}

.individualaob__modalPOpup__headerTwo {
  width: 536px;
}
.individualaob__modalPOpup__headerTwo > div {
  font-family: "GeneralSans-Medium";
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #343d46;
}

.individualaob__button__row > button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.individualaob__button__row > button > span:nth-child(1) {
  width: 10%;
  /* background-color: red;*/
}
.individualaob__button__row > button > span:nth-child(2) {
  width: 80%;
  font-weight: 600;
  font-size: 18px;
  color: #21153a;
  /* background-color: yellow; */
}
.ant-modal-content {
  border-radius: 16px !important;
}

.ant-modal-footer {
  display: none;
}
.ant-modal-header {
  display: none;
}

.individualaob__drawer__headerone {
  color: #000000;
  text-align: center;
}

.individualaob__drawer__headerTwo {
  color: #343d46;
  font-weight: 500;
  text-align: center;
}
.individualaob__drawer__headerTwo__paragraph {
  /* background-color: yellow; */
  margin-top: -0.5rem;
}

.individualaob__drawer__button__row {
  display: flex;
  justify-content: space-between;
}

.individualaob__button > button {
  padding: 10%;
}
.individualaob__drawer__headerone__content {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
        align-items: center; */
}

.aob__register .tab-inner-travel .ant-tabs-tab {
  min-width: 18rem;
}

@media screen and (max-width: 525px) {
  .aob__register .tab-inner-travel .ant-tabs-tab {
    min-width: 135px;
  }
}

/* @media only screen and (max-width: 374px) and (min-width: 321px)  {
            .oona-mob-non-selected{
                margin-right:8px !important
            }
    } */

.heading__content__oonaAOB {
  color: #482c77;
  font-weight: 600;
  font-size: 30px;
  margin-left: 5%;
}

.arrow__left__outlined {
  display: flex;
  width: 10%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.aob__marin__top {
  margin-top: 2%;
}

.next-button-oona {
  background-color: #482c77;
  border-color: #482c77 !important;
  color: white;
  border-radius: 10px;
  width: 27%;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}

.next-button-oona__skip{
  /* background-color: #482c77;
  border-color: #482c77 !important; */
  color: #482c77;
  border-radius: 10px;
  width: 27%;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 10px;
  float: right;
  text-align: center;
  margin-right:1em

}

.oona-mob-non-selected {
  background-color: rgb(175, 164, 164);
  color: white;
  /* padding: 0px 9px; */
  margin-right: 5px;
  border-radius: 25px;
  text-align: center;
}

.oona-mob-selected {
  background-color: #4ac6bb;
  color: white;
  text-align: center;
  border-radius: 25px;
  margin-right: 5px;
}

.oona-heading-content {
  color: #482c77;
  font-weight: 600;
  font-size: 18px;
}

.oona__success__text__title {
  font-size: 2.8rem;
  font-weight: 600;
  color: #482c77;
  line-height: 49.2px;
}
.oona__success__text__middle {
  font-weight: 500;
  font-size: 1rem;
  line-height: 24px;
}
.CreatingAgentAccount__header {
  color: #482c77;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 1%;
  font-family: "GeneralSans-Bold";
}

.content-form-dashboard {
  inline-size: max-content;
}

.CreatingAgentAccount__passwordText {
  color: #343d46;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.CreatingAgentAccount__phoneNumber {
  color: #343d46;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
}

.agentOTPInput {
  border: none;
  outline: none;
  border-bottom: 1px solid #818f99;
  width: 96%;
  padding: 14px 8px 14px 8px;
  text-align: center;
}
.agentOTPInput:focus {
  border: none;
  outline: none;
  border-bottom: 1px solid #818f99;
}

.otpConfirmation__footer {
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #343d46;
}

.otpConfirmation__resendfooter {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #482c77;
}

/* .aob__register .ant-modal-close{
    background-color: yellow;
  } */

.aob__modal__button {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #dcd6e9;
  border-radius: 12px;
}

.submit__onna__button {
  margin-top: 2rem;
  /* height:58px */
}

.oona__error {
  display: flex;
  flex-direction: column;
  line-height: 24px;
  text-align: center;
  color: #343d46;
}
.oona__error div:nth-child(2) {
  margin-top: 1rem;
}

.otp__font {
  font-size: 16px;
}

/* Media Query for low resolution  Tablets, Ipads */
/* @media (min-width: 481px) and (max-width: 767px) {
   .oona__aob__progress__text{
    background-color: yellow;
   }
} */

/* Media Query for Tablets Ipads portrait mode */
/* @media (min-width: 768px) and (max-width: 1024px){
    .oona__aob__progress__text{
        background-color: blue;
       }
} */

/* Medium devices (landscape tablets, 768px and up) */

/* @media screen and (min-width: 601)  and (max-width: 768px){
     .arrow__left__outlined{
        width:17%
     }
    
     .aob__register .tab-inner-travel .ant-tabs-tab{
        min-width:350px !important
     }
     

    } */

.Change__tier__parent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.changetier__button {
  border-color: #dcd6e9 !important;
  border-radius: 12px;
  width: 48%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "GeneralSans-Medium";
}

.changetier__button__confirm {
  background-color: #482c77;
  color: #dcd6e9;
  border-color: #dcd6e9 !important;
  border-radius: 12px;
  width: 48%;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  font-family: "GeneralSans-Medium";
}

.change_Submit_Button {
  background-color: #482c77;
  color: white;
  width: 100%;
  padding: 6px, 16px, 6px, 16px;
  border-radius: 12px;
  height: 48px;
}

.CreatingAgentAccount__phoneNumber
  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.aob__register .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.aob__taxpayer  .ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}



.change__tier {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
}


.next-button-oona__disabled{
  background-color: #C8CDD1;
  border-color: #C8CDD1 !important;
  color: #818F99;
  border-radius: 10px;
  width: 27%;
  height: 48px;
  margin-top: 2rem;
  margin-bottom: 10px;
  float: right;
  text-align: center;
}



.continue__button{
  background-color: #482C77;
  padding: 6px, 20px, 20px, 16px;
  border-radius: 12px;
  width: 100%;
  color: white;

}

.topmobileMargin{
  margin-top: 78px;
}
body::-webkit-scrollbar {
    display: contents;
    width: 5px;
    height: 300px;
    margin-left: 40px;

}

body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgb(153 131 131 / 30%);
}

body::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

/* model-scroll-bar */
.custom-scroll-content {
    height: 400px;
    overflow-y: scroll;
    margin-top: 20px;
}

/* Width and height of the scrollbar track */
.custom-scroll-content::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    display: contents;

}

/* Color of the scrollbar track */
.custom-scroll-content::-webkit-scrollbar-track {
    background: #f5f5f5;
}

/* Color of the scrollbar thumb (the draggable part) */
.custom-scroll-content::-webkit-scrollbar-thumb {
    background-color: rgb(197, 193, 193);
    border-radius: 5px;
}

/* When hovering over the scrollbar */
.custom-scroll-content::-webkit-scrollbar-thumb:hover {
    background-color: #7a5baf;
}

.custom-scroll-content .ant-radio-group {
    width: 100%;
}
